import React from 'react'
import { cLoader } from './styles.module.scss'
import classNames from 'classnames'

const Loader = ({ className }) => {
  const loaderClasses = classNames({
    [cLoader]: true,
    [className]: !!className,
  })

  return <div className={loaderClasses}>Loading...</div>
}

export default Loader
